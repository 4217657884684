import Vue from 'vue';

let audio = new Audio('/static/audio/message_sound.mp3');

function playNotification() {
    audio.load();
    try {
        audio.play(); // ios no interaction error message possible.
    } catch (e) {
    }
}

export const onNewRequestAlert = async (e) => {
    /**
     * @param {LiveEventNewRequestAlert} e
     */
    const $rootScope = Vue.getAngularModule('$rootScope');
    Vue.notifications.Notification({
        delay: 8000,
        title: 'New Request',
        templateUrl: 'ws.alert.onNewAlert.html',
        scope: $rootScope
    });
    $rootScope.$broadcast('update_tasks_counters');
    playNotification();
};

/**
 * Created by orion on 6/21/17.
 */
let angular = require('angular');

class CurrentUser {
    constructor () {
        this.user = window.$user;  // from global
    }

    has_permission (code) {
        if (!this.is_authenticated()) {
            return false;
        }
        return this.user.permissions[code] !== 2;
    }

    confirm_permission (code) {
        if (!this.is_authenticated()) {
            return false;
        }
        return this.user.permissions[code] === 1;
    }

    is_authenticated () {
        return this.user.is_authenticated;
    }

    is_permission_forbidden (code) {
        return this.user.permissions[code] === 2;
    }

    is_superuser () {
        if (!this.is_authenticated()) {
            return false;
        }
        return this.user.is_superuser;
    }

    get is_searchable () {
        if (!this.is_authenticated()) {
            return false;
        }
        return this.user.is_searchable;
    }

    set is_searchable (val) {
        this.user.is_searchable = val;
        window.$user.is_searchable = val;
    }

    is_ah () {
        if (!this.is_authenticated()) {
            return false;
        }
        return this.user.is_ah;
    }

    ah_terms () {
        if (!this.is_authenticated()) {
            return {};
        }
        return this.user.ah_terms;
    }

    ah_link () {
        return this.user.ah_link;
    }

    is_guardian_of (id) {
        if (!this.is_authenticated()) {
            return false;
        }
        return this.dependents_profiles_id_list.indexOf(id) != -1;
    }

    is_group_admin (group_id) {
        if (!this.is_authenticated()) {
            return false;
        }
        return this.user.managed_groups_id_list.indexOf(group_id) != -1;
    }

    is_community_admin (community_id) {
        if (!this.is_authenticated()) {
            return false;
        }
        return this.user.managed_communities_id_list.indexOf(community_id) != -1;
    }

    is_admin_of_any_community () {
        return this.user.managed_communities_id_list.length;
    }

    is_circle_user () {
        if (!this.is_authenticated()) {
            return false;
        }
        return !!this.user.is_circle_user;
    }

    is_buddy () {
        if (!this.is_authenticated()) {
            return false;
        }
        return !!this.user.is_buddy;
    }

    can_see_insights_link () {
        return this.is_superuser() || this.is_ah() || this.is_buddy();
    }

    get can_create_diary_proxy_entries () {
        if (!this.is_authenticated()) {
            return false;
        }
        return this.user.can_create_diary_proxy_entries;
    }

    get id () {
        return this.user.userprofile_id;
    }

    get wiki_id () {
        return this.user.wiki_id;
    }

    get ah_id () {
        return this.user.ah_id;
    }

    get link () {
        return this.user.profile_link;
    }

    get blog_id () {
        return this.user.blog_id;
    }

    get ws_sign () {
        return this.user.ws_sign;
    }

    get name () {
        return this.user.username;
    }

    get username () {
        return this.user.username;
    }

    get first_name () {
        return this.user.first_name;
    }

    get first_name_or_username () {
        return this.user.first_name || this.user.username;
    }

    set first_name (val) {
        this.user.first_name = val;
        window.$user.first_name = val;
    }

    get last_name () {
        return this.user.last_name;
    }

    set last_name (val) {
        this.user.last_name = val;
        window.$user.last_name = val;
    }

    get avatar () {
        return this.user.avatar;
    }

    get email () {
        return this.user.email;
    }

    get dashboard_config () {
        return this.user.dashboard_config;
    }

    get pending_to_delete () {
        return this.user.pending_to_delete;
    }

    set pending_to_delete (val) {
        return this.user.pending_to_delete = val;
    }

    get wiki_enabled () {
        if (!this.is_authenticated()) {
            return false;
        }
        return this.user.wiki_enabled;
    }

    get is_wiki_user () {
        return this.wiki_enabled;
    }

    get is_policy_signed () {
        return this.user.is_policy_signed;
    }

    get is_my_story_empty () {
        return this.user.is_my_story_empty;
    }

    get is_my_media_empty () {
        return this.user.is_my_media_empty;
    }

    set is_policy_signed (val) {
        this.user.is_policy_signed = val;
    }

    get visibility_changes_signed () {
        return this.user.visibility_changes_signed;
    }

    get password_marked_as_weak () {
        if (!this.is_authenticated()) {
            return false;
        }
        return this.user.password_marked_as_weak;
    }

    get first_and_last_name_set_postponed () {
        return this.user.first_and_last_name_set_postponed;
    }

    get fill_profile_story_postponed () {
        return this.user.fill_profile_story_postponed;
    }

    get weak_password_reminder_postponed () {
        if (!this.is_authenticated()) {
            return false;
        }
        return this.user.weak_password_reminder_postponed;
    }

    get add_avatar_postponed () {
        return this.user.add_avatar_postponed;
    }

    set visibility_changes_signed (val) {
        this.user.visibility_changes_signed = val;
    }

    get dependents_profiles_id_list () {
        if (!this.is_authenticated()) {
            return [];
        }
        return this.user.dependents_profiles_ids_and_names_list.map(p => p.id);
    }

    get dependents_profiles_ids_and_names_list () {
        if (!this.is_authenticated()) {
            return [];
        }
        return this.user.dependents_profiles_ids_and_names_list;
    }

}

angular.module('mmApp.shared.CurrentUser', [])
  .service('CurrentUser', CurrentUser)

;

export default 'mmApp.shared.CurrentUser';
export { CurrentUser as CurrentUserClass };

export const LiveEventCode = {
    newConversationMessage: 'new_conversation_message',
    newConversationMessageLive: 'new_conversation_message_live', // for my own posts
    conversationMessageLikeOrUnlike: 'conversation_message_like_or_unlike',
    conversationMessageDeleted: 'conversation_message_deleted',
    conversationMessageApproved: 'conversation_message_approved',
    conversationTypingStateChanged: 'conversation_typing_state_changed',
    conversationRead: 'conversation_read',
    newRequestAlert: 'new_request_alert',
};

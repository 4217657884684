import { Centrifuge, UnauthorizedError } from 'centrifuge';
import { CurrentUserClass } from '~/angular/app/shared/CurrentUser';
import { onNewConversationMessageDefaultListener } from '~/instant_messages/defaultListeners/newConversationMessage';
import { LiveEventCode } from '~/instant_messages/event_codes';
import { onNewRequestAlert } from '~/instant_messages/defaultListeners/newRequestAlert';
import axios from 'axios';

const CENTRIFUGO_ENDPOINT = CENTRIFUGO_CLIENT_ENDPOINT;

async function getToken() {
    if (!new CurrentUserClass().is_authenticated()) {
        return '';
    }
    const res = await fetch('/centrifuge/jwt/');
    if (!res.ok) {
        if (res.status === 403) {
            // Return special error to not proceed with token refreshes, client will be disconnected.
            throw new UnauthorizedError();
        }
        // Any other error thrown will result into token refresh re-attempts.
        throw new Error(`Unexpected status code ${res.status}`);
    }
    const data = await res.json();
    return data.token;
}

let centrifugeInstance;

const listeners = {};

export const addWsEventListener = (code, listener, isDefault = false) => {
    if (listeners[code]?.length) {
        if (!listeners[code].includes(listener)) {
            if (isDefault) {
                listeners[code].push(listener);
            } else {
                listeners[code].unshift(listener);
            }
        }

    } else {
        listeners[code] = [listener];
    }
};
export const removeWsEventListener = (code, listener) => {
    if (listeners[code] && listeners[code].includes(listener)) {
        listeners[code].splice(listeners[code].indexOf(listener), 1);
    }
};

function conversationTypingSubscriptionFactory(conversationId) {
    return async function getToken() {
        try {
            const resp = await axios(DjangoUrls['api.v1:conversations-subscribe-for-typing'](conversationId),
                {
                    method: 'POST',
                });
            return resp.data.token;
        } catch (e) {
            if (e.response.status === 403) {
                throw new UnauthorizedError();
            }
            throw new Error(`Unexpected status code ${e.response.status}`);
        }
    };
}

const processWsEvent = async (event) => {
    if (listeners[event.event_code]) {
        for (const l of listeners[event.event_code]) {
            const stop = await l(event);
            if (stop) {
                break;
            }
        }
    }
};

export const subscribeToConversationTypingChannel = async (conversationId) => {
    const channelName = `conversationTyping:#${conversationId}`;
    const exSub = centrifugeInstance.getSubscription(channelName);
    if (exSub) {
        exSub.subscribe();
        return exSub;
    }
    const sub = centrifugeInstance.newSubscription(channelName, {
        getToken: conversationTypingSubscriptionFactory(conversationId),
    });
    sub.subscribe();
    sub.on('publication', (ctx) => {
        processWsEvent(ctx.data);
    });
    return sub;
};

const bindDefaultListeners = () => {
    addWsEventListener(LiveEventCode.newConversationMessage, onNewConversationMessageDefaultListener, true);
    addWsEventListener(LiveEventCode.newRequestAlert, onNewRequestAlert, true);
};

const connectCentrifugo = async () => {
    if (centrifugeInstance !== undefined) {
        centrifugeInstance.disconnect();
    }
    centrifugeInstance = new Centrifuge(`${CENTRIFUGO_ENDPOINT}/connection/websocket`, {
        debug: false,
        getToken,
    });
    centrifugeInstance.on('connecting', function (ctx) {
        console.debug(`connecting: ${ctx.code}, ${ctx.reason}`);
    }).on('connected', async function (ctx) {
        bindDefaultListeners();
        console.debug('connected to ws!');
    }).on('disconnected', function (ctx) {
        console.debug(`disconnected: ${ctx.code}, ${ctx.reason}`);
    }).connect();
    centrifugeInstance.on('publication', (ctx) => {
        console.debug('publication', ctx.data);
        processWsEvent(ctx.data);
    });
    centrifugeInstance.connect();
};

export default connectCentrifugo;
